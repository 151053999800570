
import {Component, Vue} from 'vue-property-decorator';
import Http from '@/Http';

@Component({})
export default class Auto extends Vue {

  title = 'Выполняется вход...';
  progress = true;

  created() {

    Http.post('/login/auto', {
      hash: this.$route.query.hash
    }).then((value: any) => {
      localStorage.setItem('token', value.token);
      this.title = 'Переход в личный кабинет.';
      document.location.href = '/';
    }).finally(() => {

    }).catch((HttpError) => {
      const redLabel = ' Вы будете перенаправлены на старницу авторизации...';

      switch (HttpError.error) {
        case 'already_logged':
          this.title = 'Вы уже авторизированы, перенаправлене на главную страницу.';
          setTimeout(() => {
            this.$router.push('/');
          }, 2000);
          break;
        case 'hash_invalid':
          this.title = 'Неверные параметры для входа.' + redLabel;
          setTimeout(() => {
            document.location.href = '/login/in';
          }, 3000);
          break;
        case 'hash_expired':
          this.title = 'Данная ссылка устарела.' + redLabel;
          setTimeout(() => {
            document.location.href = '/login/in';
          }, 3000);
          break;


        case '_invalid':
          break;
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

}
